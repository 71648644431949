import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import config from "../config";
import { TiTick } from "react-icons/ti";

export default function EditSizeCard() {
  /**
   * handle authentication
   */
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token"]);
  const checkAuthentication = async () => {
    if (cookies) {
      const result = await axios.post(`${config.baseUrl}auth/status`, {
        access_token: cookies.access_token,
      });
      if (!result.data.authenticated) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  /**
   *  end authentication
   */
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState<File | null | undefined>(
    null
  );
  let [previousData, setPreviousData] = useState<any>();
  const [imageDeleted, setImageDeleted] = useState(false);
  const [imageDeleteLoading, setImageDeleteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataUploaded, setDataUploaded] = useState(false);
  const fetchPreviousData = async () => {
    try {
      const data = await axios.get(`${config.baseUrl}product/${id}`);
      setPreviousData(data.data);
    } catch (error) {
      console.log("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    fetchPreviousData();
  }, [id]);
  const [errors, setErrors] = useState({
    sizeChart: "",
  });
  const [sizeChart, setSizeChart] = useState({ src: "", alt: "" });
  const [sizeChartDeleted, setSizeChartDeleted] = useState(false);
  useEffect(() => {
    if (previousData && previousData.sizeChart) {
      setSizeChart({
        src: previousData.sizeChart.src,
        alt: previousData.sizeChart.alt,
      });
    }
  }, [previousData]);

  return (
    <>
      <div className="container" style={{ marginTop: "5rem", color: "black" }}>
        <fieldset
          style={{
            width: "100%",
            padding: "2rem 1rem",
            border: "2px dashed black",
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <legend
            style={{
              position: "absolute",
              top: "-1.3rem",
              backgroundColor: "white",
              width: "auto",
              padding: "0 5px",
            }}
          >
            Current Size Chart
          </legend>
          {sizeChart.src && sizeChart.src.length > 0 ? (
            <img
              src={sizeChart.src}
              style={{ width: "50vh", height: "50vh" }}
            ></img>
          ) : (
            <div
              style={{
                width: "50vh",
                height: "50vh",
                fontSize: "2rem",
                display: "flex",
                color: "white",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgb(68, 68, 68)",
              }}
            >
              No Size Chart
            </div>
          )}
        </fieldset>
        <div
          style={{
            marginTop: "1rem",
            marginBottom: "5px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <>
            {sizeChart.src && (
              <button
                disabled={
                  (sizeChart && sizeChart.src.length < 1) || imageDeleteLoading
                }
                className="btn btn-danger"
                style={{ borderRadius: "0", marginRight: "1rem" }}
                onClick={(event) => {
                  setSizeChart({ src: "", alt: "" });
                  setSizeChartDeleted(true);
                }}
              >
                Delete Image
              </button>
            )}

            <button
              className="btn btn-primary"
              style={{ borderRadius: "0" }}
              disabled={!sizeChartDeleted}
              onClick={async (event) => {
                setSizeChartDeleted(false);
                try {
                  let result = await axios.delete(
                    `${config.baseUrl}product/sizeChart/${id}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );
                } catch (error) {
                  console.log(error);
                }
                setImageDeleted(true);
              }}
            >
              Save Changes
            </button>
          </>
        </div>
        <div
          style={{
            fontSize: "1.5rem",
            backgroundColor: "green",
            marginBottom: "3rem",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          {imageDeleted && (
            <span>
              Size Chart Deleted Successfully
              <TiTick style={{ fontSize: "2rem" }}></TiTick>
            </span>
          )}
        </div>
        <div className="mb-3">
          <fieldset
            className="mb-4"
            style={{
              width: "100%",
              height: "100px",
              border: "2px dashed black",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <legend
              style={{
                position: "absolute",
                top: "-1.3rem",
                backgroundColor: "white",
                color: "black",
                width: "auto",
                padding: "0 1rem",
              }}
            >
              New Size Chart
            </legend>
            <input
              type="file"
              name="selectedImage"
              id="editSizeCard-selectedImage"
              accept="image/*"
              onChange={(event) => {
                const selectedFile = event.target.files?.item(0);
                const fileInput = event.target;
                if (!selectedFile?.type.startsWith("image/")) {
                  setErrors((prevErrors) => {
                    return {
                      ...prevErrors,
                      sizeChart: "Please select an image",
                    };
                  });
                  event.target.files = null;
                  setSelectedImage(null);
                  fileInput.value = "";
                  return;
                } else {
                  setSelectedImage(selectedFile);
                  setErrors((prevErrors) => {
                    return { ...prevErrors, sizeChart: "" };
                  });
                }
              }}
              style={{
                backgroundColor: "gray",
                color: "white",
              }}
              required
            />
            <span style={{ color: "red" }}>{errors.sizeChart}</span>
          </fieldset>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "3rem",
            }}
            onClick={async (event) => {
              if (!selectedImage) {
                setErrors((prevErrors) => {
                  return {
                    ...prevErrors,
                    sizeChart: "please select an image",
                  };
                });
              }
              if (selectedImage?.size && selectedImage?.size / 1024 > 400) {
                setErrors((prevErrors) => {
                  return {
                    ...prevErrors,
                    sizeChart: "please select an image less than 400kb",
                  };
                });
              }
              if (errors.sizeChart.length < 1 && selectedImage) {
                setIsLoading(true);
                let tempFormData = new FormData();
                tempFormData.append("file", selectedImage);

                let result = await axios.post(
                  `${config.baseUrl}product/upload/image/one`,
                  tempFormData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
                //   ${config.imageBaseUrl}${result.data.filename}
                await axios.put(
                  `${config.baseUrl}product/sizeChart/${id}`,
                  {
                    src: `${config.imageBaseUrl}${result.data.filename}`,
                    alt: "size chart",
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );
                setIsLoading(false);
                setDataUploaded(true);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                window.location.reload();
                setDataUploaded(false);
              }
            }}
          >
            <button className="btn btn-primary" style={{ borderRadius: "0" }}>
              Update Size Chart
            </button>
          </div>
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "0",
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0,0,0,0.8)",
            backdropFilter: "blur(4px)",
            zIndex: "99999",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          {dataUploaded ? (
            <>
              <TiTick
                style={{ fontSize: "2rem", width: "2rem", height: "2rem" }}
              ></TiTick>
            </>
          ) : (
            <>
              <div
                className="spinner-border"
                style={{ width: "2rem", height: "2rem", marginBottom: "1rem" }}
                role="status"
              ></div>
            </>
          )}

          <span>Updating Size Card</span>
        </div>
      )}
    </>
  );
}
