import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { ProductsData } from "../types/TProduct";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import config from "../config";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Dashboard() {
  /**
   * handle authentication
   */
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token"]);
  const checkAuthentication = async () => {
    if (cookies) {
      const result = await axios.post(`${config.baseUrl}auth/status`, {
        access_token: cookies.access_token,
      });
      if (!result.data.authenticated) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  /**
   *  end authentication
   */
  const [products, setProducts] = useState<ProductsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchProducts = async () => {
    setLoading(true);
    setError(null);
    try {
      // const response = await axios.get("http://127.0.0.1:3000/product");
      const response = await axios.get(`${config.baseUrl}product`);
      setProducts(response.data);
    } catch (error: any) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        setError("Failed to fetch products.");
      } else if (error.request) {
        console.error("Request error:", error.request);
        setError("No response from server.");
      } else {
        console.error("General error:", error.message);
        setError("An error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  // useEffect(() => {
  //   console.log(products);
  // }, [products]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PKR",
    maximumFractionDigits: 0,
  });
  return (
    <div style={{ marginTop: "5rem" }}>
      <button
        onClick={fetchProducts}
        disabled={loading}
        className="btn-dark"
        style={{
          marginLeft: "2rem",
          marginBottom: "1rem",
          border: "none",
          color: "white",
          width: "100px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <div
            className="spinner-border"
            style={{ width: "1.4rem", height: "1.4rem" }}
            role="status"
          ></div>
        ) : (
          "Refresh"
        )}
      </button>

      {error && <div style={{ color: "red" }}>{error}</div>}

      <table className="table">
        <thead>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "center",
                minWidth: "70px",
              }}
            >
              id
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                minWidth: "120px",
              }}
            >
              Category
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                minWidth: "300px",
              }}
            >
              Images
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                minWidth: "300px",
              }}
            >
              Size Chart
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                minWidth: "150px",
              }}
            >
              Title
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "200px" }}>
              Description
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "100px" }}>
              Sizes
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Styles
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Price
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Discount
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Is OutOfStock
            </th>
          </tr>
        </thead>
        <tbody>
          {products &&
            products.map((product) => (
              <tr>
                <th
                  scope="row"
                  key={product.id}
                  style={{ textAlign: "center" }}
                >
                  {product.id}
                </th>
                <td style={{ textAlign: "center" }}>{product.category}</td>
                <td style={{ textAlign: "center" }}>
                  {product.images &&
                    Array.isArray(product.images) &&
                    product.images.map((img, index) => (
                      <div key={index}>
                        {img.src ? img.src : ""}
                        <br></br>
                      </div>
                    ))}
                </td>
                <td style={{ textAlign: "center" }}>
                  {/* come here */}
                  {product.sizeChart ? product.sizeChart.src : ""}
                  {/* {product.images &&
                    Array.isArray(product.images) &&
                    product.images.map((img, index) => (
                      <div key={index}>
                        {img.src ? img.src : ""}
                        <br></br>
                      </div>
                    ))} */}
                </td>
                <td style={{ textAlign: "center" }}>{product.title}</td>
                <td style={{ textAlign: "center" }}>
                  {product.description ? (
                    <>
                      {product.description.length > 24 ? (
                        <>{product.description.substring(0, 24)}...</>
                      ) : (
                        <>{product.description}</>
                      )}
                    </>
                  ) : null}
                </td>
                <td style={{ textAlign: "center" }}>
                  {product.sizes &&
                    Array.isArray(product.sizes) &&
                    product.sizes.map((size, index) => (
                      <div key={index}>
                        {size}
                        <br />
                      </div>
                    ))}
                </td>
                <td style={{ textAlign: "center" }}>
                  {product.styles &&
                    Array.isArray(product.styles) &&
                    product.styles.map((style, index) => (
                      <div key={index}>
                        {style}
                        <br />
                      </div>
                    ))}
                </td>
                <td style={{ textAlign: "center" }}>
                  {formatter.format(Number.parseInt(product.price))}
                </td>
                <td style={{ textAlign: "center" }}>
                  {Number.parseInt(product.discountPercentage)}%
                </td>
                <td style={{ textAlign: "center" }}>
                  {product.isOutOfStock ? "Yes" : "No"}
                </td>
              </tr>
            ))}
          {/* <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr> */}
        </tbody>
      </table>
      {/* <div style={{ color: "black" }}>{products && products[0].title}</div>; */}
    </div>
  );
}
