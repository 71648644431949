import React, { useCallback, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import NavBar from "./components/navBar";
import {
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Products from "./pages/products";
import AddNewProduct from "./pages/addNewProduct";
import EditProduct from "./pages/editProduct";
import Messages from "./pages/messages";
import Orders from "./pages/orders";
import PreviousOrders from "./pages/previousOrders";
import Login from "./pages/login";
import { useCookies } from "react-cookie";
import axios from "axios";
import config from "./config";
import EditSizeCard from "./pages/editSizeCard";
// import CheckAuthentication from "./functions/checkAuthentication";
// const AdminPanel: React.FC = () => {
//   return <div>Admin Panel</div>;
// };
function App() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token"]);
  const checkAuthentication = async () => {
    if (cookies) {
      const result = await axios.post(`${config.baseUrl}auth/status`, {
        access_token: cookies.access_token,
      });
      if (!result.data.authenticated) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  return (
    <>
      <NavBar></NavBar>
      <Routes>
        <Route path="/" element={<Dashboard></Dashboard>}></Route>
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/products" element={<Products></Products>}></Route>
        <Route
          path="/newProduct"
          element={<AddNewProduct></AddNewProduct>}
        ></Route>
        <Route
          path="/editProduct/:id"
          element={<EditProduct></EditProduct>}
        ></Route>
        <Route
          path="/editSizeCard/:id"
          element={<EditSizeCard></EditSizeCard>}
        ></Route>
        <Route path="/messages" element={<Messages></Messages>}></Route>
        <Route path="/orders" element={<Orders></Orders>}></Route>
        <Route
          path="/previousOrders"
          element={<PreviousOrders></PreviousOrders>}
        ></Route>
      </Routes>
    </>
  );
}

export default App;

// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import NavBar from "./components/navBar";
// import Dashboard from "./pages/dashboard";
// import Products from "./pages/products";
// import AddNewProduct from "./pages/addNewProduct";
// import EditProduct from "./pages/editProduct";
// import Messages from "./pages/messages";
// import Orders from "./pages/orders";
// import PreviousOrders from "./pages/previousOrders";
// import { AuthProvider } from "./contexts/AuthContext";
// import ProtectedRoute from "./ProtectedRoute";
// import Login from "./pages/login";
// import Logout from "./Logout";

// const AdminPanel: React.FC = () => {
//   return <div>Admin Panel</div>;
// };

// const App: React.FC = () => {
//   return (
//     // <Router>
//     <AuthProvider>
//       <NavBar />
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route path="/logout" element={<Logout />} />
//         {/* <ProtectedRoute path="/admin" element={<AdminPanel />} /> */}
//         <Route
//           path="/admin"
//           element={<ProtectedRoute path="/admin" element={<AdminPanel />} />}
//         />
//         <Route path="/" element={<Navigate to="/login" />} />
//         <Route path="/dashboard" element={<Dashboard />} />
//         <Route path="/products" element={<Products />} />
//         <Route path="/newProduct" element={<AddNewProduct />} />
//         <Route path="/editProduct/:id" element={<EditProduct />} />
//         <Route path="/messages" element={<Messages />} />
//         <Route path="/orders" element={<Orders />} />
//         <Route path="/previousOrders" element={<PreviousOrders />} />
//       </Routes>
//     </AuthProvider>
//     // </Router>
//   );
// };

// export default App;
