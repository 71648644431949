import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
// import { useAuth } from './AuthContext';

const Login: React.FC = () => {
  const [cookies, setCookie] = useCookies(["access_token"]);
  const [isLoading, setIsLoading] = useState(false);
  /**
   * handle authentication
   */
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const checkAuthentication = async () => {
    if (cookies) {
      const result = await axios.post(`${config.baseUrl}auth/status`, {
        access_token: cookies.access_token,
      });
      if (!result.data.authenticated) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
        navigate("/");
      }
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  /**
   *  end authentication
   */
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState("");
  useEffect(() => {
    setUsername("");
    setPassword("");
  }, []);
  const handleLogin = async (e: React.FormEvent) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const result = await axios.post(
        `${config.baseUrl}auth/login`,
        { username, password },
        { withCredentials: true }
      );
      setCookie("access_token", result.data.access_token);
      setUsername("");
      setPassword("");
      navigate("/");
    } catch (error) {
      setErrors("username or password is incorrect");
    }
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={handleLogin}
      style={{
        color: "black",
        backgroundColor: "white",
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "0",
        zIndex: "999999999",
      }}
    >
      {/* <label
        htmlFor="login-usernameInput"
        style={{
          width: "300px",
          fontSize: "1.4rem",
        }}
      >
        Enter Username
      </label> */}
      <img
        src={require("../assets/img/logo.png")}
        style={{ filter: "invert()", width: "150px", marginBottom: "2rem" }}
        alt=""
      />
      <input
        id="login-usernameInput"
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="username"
        style={{
          width: "300px",
          padding: "5px 10px",
          marginBottom: "1rem",
        }}
      />
      <input
        id="login-passwordInput"
        type="password"
        value={password}
        placeholder="password"
        onChange={(e) => setPassword(e.target.value)}
        style={{
          width: "300px",
          padding: "5px 10px",
          marginBottom: "1.5rem",
        }}
      />
      <button
        type="submit"
        style={{
          backgroundColor: "var(--navBarBG)",
          color: "white",
          width: "100px",
          height: "40px",
          fontSize: "1.3rem",
        }}
        disabled={isLoading}
        onClick={() => {}}
      >
        {isLoading ? (
          <div
            className="spinner-border"
            style={{ width: "1.3rem", height: "1.3rem" }}
          ></div>
        ) : (
          "Login"
        )}
      </button>
      <span
        style={{
          color: "red",
          //   backgroundColor: "black",
          height: "40px",
          width: "100%",
          textAlign: "center",
          marginTop: "5px",
        }}
      >
        {errors}
      </span>
    </form>
  );
};

export default Login;
