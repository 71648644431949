import axios from "axios";
import { useEffect, useState } from "react";
import config from "../config";
import { convertToPakistanTime } from "../functions/convertToPakistanTime";
import { IoIosSend } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { convertToDate } from "../functions/coverToDate";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Messages() {
  /**
   * handle authentication
   */
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token"]);
  const checkAuthentication = async () => {
    if (cookies) {
      const result = await axios.post(`${config.baseUrl}auth/status`, {
        access_token: cookies.access_token,
      });
      if (!result.data.authenticated) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  /**
   *  end authentication
   */
  const [messages, setMessages] = useState<
    | {
        id: number;
        fullName: string;
        email: string;
        subject: { subject: string; sentAt: string }[];
        message: { message: string; sentAt: string }[];
        createdAt: Date;
        updatedAt: Date;
      }[]
    | null
  >();
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<{
    id: number;
    fullName: string;
    email: string;
    subject: { subject: string; sentAt: string }[];
    message: { message: string; sentAt: string }[];
    createdAt: Date;
    updatedAt: Date;
  } | null>(null);
  const fetchData = async () => {
    let data = await axios.get(`${config.baseUrl}messages`);
    setMessages(data.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleDelete = async (id: number) => {
    setLoading(true);
    setIsDeleted(false);
    const result = await axios.delete(`${config.baseUrl}messages/${id}`);
    setIsDeleted(true);
    setLoading(false);
    fetchData();
  };
  return (
    <div style={{ marginTop: "5rem" }}>
      <div
        style={{
          color: "rgba(0, 0, 0, 0.8)",
          textAlign: "center",
          fontSize: "2rem",
          //   backgroundColor: "red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Messages
        <IoIosSend
          style={{
            // marginLeft: "5px",
            fontSize: "2.5rem",
          }}
        ></IoIosSend>
      </div>
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "black",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
        }}
      ></div>
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {messages && messages.length > 0 ? (
          messages.map(
            (
              message: {
                id: number;
                fullName: string;
                email: string;
                subject: { subject: string; sentAt: string }[];
                message: { message: string; sentAt: string }[];
                createdAt: Date;
                updatedAt: Date;
              },
              index: number
            ) => {
              let updateDate = new Date(message.updatedAt);
              //   let updateDate = message.updatedAt;
              return (
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="messageCard-container"
                  style={{
                    width: "80vw",
                    height: "70px",
                    border: "2px solid rgba(0, 0, 0, 0.4)",
                    color: "rgba(0, 0, 0, 0.8)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 1rem",
                    margin: "1rem 0",
                    boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                  }}
                  onClick={() =>
                    setSelectedMessage(
                      messages.filter((_m, _i) => _m.id === message.id)[0]
                    )
                  }
                >
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "1.4rem", fontWeight: "550" }}>
                      {message.fullName}
                    </span>
                    <span style={{ fontSize: "1.1rem", fontStyle: "italic" }}>
                      {message.email}
                    </span>
                  </span>
                  <span>{`${updateDate.toDateString()}, ${updateDate.toTimeString()}`}</span>
                </div>
              );
            }
          )
        ) : (
          <div
            style={{
              color: "rgba(0,0,0,0.5)",
              fontSize: "1.3rem",
              marginTop: "1rem",
            }}
          >
            No Messages to Show
          </div>
        )}
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content" style={{ color: "black" }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {isDeleted ? "Deleted Successfully" : "Message"}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                disabled={loading}
                onClick={() => {
                  setIsDeleted(false);
                  setLoading(false);
                }}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "1.2rem",
                height: "500px",
                overflow: "auto",
              }}
            >
              {isDeleted ? (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <TiTick
                      style={{
                        fontSize: "5rem",
                        backgroundColor: "green",
                        borderRadius: "5rem",
                        color: "white",
                        marginBottom: "5px",
                      }}
                    ></TiTick>
                  </span>
                  <span>Message is successfully deleted!</span>
                </div>
              ) : (
                // <>Are you sure you want to delete this product?</>
                /**
                 *      content of message
                 */
                <>
                  {selectedMessage?.subject.map((_s, _i) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontWeight: "550", marginRight: "5px" }}
                          >
                            Subject:{" "}
                          </span>
                          <span>{_s.subject + ""}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                            width: "100%",
                            marginTop: "7px",
                            padding: "1rem",
                          }}
                        >
                          <span>
                            {selectedMessage.message.at(_i)?.message + ""}
                          </span>
                        </div>
                        <div
                          style={{
                            fontSize: "0.8rem",
                            fontStyle: "italic",
                            width: "100%",
                            textAlign: "right",
                            marginBottom: "1rem",
                          }}
                        >{`${convertToDate(_s.sentAt)}, ${new Date(
                          _s.sentAt
                        ).toTimeString()}`}</div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                disabled={loading}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                style={{ borderRadius: "0" }}
                onClick={() => {
                  setLoading(false);
                  setIsDeleted(false);
                }}
              >
                Close
              </button>
              {!isDeleted && (
                <button
                  type="button"
                  className="btn btn-danger"
                  style={{ width: "100px", borderRadius: "0" }}
                  disabled={loading}
                  onClick={() => {
                    // if (toDelete) {
                    if (selectedMessage?.id) {
                      handleDelete(selectedMessage.id);
                    }
                    // }
                  }}
                >
                  {loading ? (
                    <div
                      className="spinner-border"
                      style={{ width: "1.3rem", height: "1.3rem" }}
                      role="status"
                    ></div>
                  ) : (
                    "Delete"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
