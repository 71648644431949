import axios from "axios";
import { lazy, useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import config from "../config";
import { TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
export default function AddNewProduct() {
  /**
   * handle authentication
   */
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token"]);
  const checkAuthentication = async () => {
    if (cookies) {
      const result = await axios.post(`${config.baseUrl}auth/status`, {
        access_token: cookies.access_token,
      });
      if (!result.data.authenticated) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  /**
   *  end authentication
   */
  /**
   *  for text editor
   */
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];
  /**
   *  end
   */
  const [loading, setLoading] = useState(false);
  const [dataUploaded, setDataUploaded] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [category, setCategory] = useState("");
  const [sizes, setSizes] = useState<string[]>([]);
  const [sizesErrors, setSizesErrors] = useState<string[]>([]);
  const [styles, setStyles] = useState<string[]>([]);
  const [stylesErrors, setStylesErrors] = useState<string[]>([]);
  const [discount, setDiscount] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image1, setImage1] = useState<File | null | undefined>(null);
  const [image2, setImage2] = useState<File | null | undefined>(null);
  const [image3, setImage3] = useState<File | null | undefined>(null);
  const [sizeChart, setSizeChart] = useState<File | null | undefined>(null);
  const [isOutOfStock, setIsOutOfStock] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    image1: "",
    image2: "",
    image3: "",
    sizeChart: "",
    title: "",
    description: "",
    price: "",
    category: "",
  });
  const addSizeInput = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setSizes([...sizes, ""]); // Add a new empty string to the sizes array
    setSizesErrors([...sizesErrors, ""]);
  };
  const addStyleInput = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setStyles([...styles, ""]); // Add a new empty string to the sizes array
    setStylesErrors([...stylesErrors, ""]);
  };

  // Function to handle changes in the input fields
  const handleSizeInputChange = (index: any, value: any) => {
    const newSizes = sizes.slice(); // Create a copy of the sizes array
    newSizes[index] = value; // Update the specific index
    setSizes(newSizes); // Set the new sizes array
  };
  const handleStyleInputChange = (index: any, value: any) => {
    const newStyles = styles.slice(); // Create a copy of the sizes array
    newStyles[index] = value; // Update the specific index
    setStyles(newStyles); // Set the new sizes array
  };

  const deleteSize = (index: number) => {
    setSizes((prevSizes) => prevSizes.filter((size, _i) => _i != index));
    setSizesErrors((prevErrors) => prevErrors.filter((e, _i) => _i != index));
  };
  const deleteStyle = (index: number) => {
    setStyles((prevStyles) => prevStyles.filter((style, _i) => _i != index));
    setStylesErrors((prevErrors) => prevErrors.filter((e, _i) => _i != index));
  };
  return (
    <>
      <form
        className="container mb-5"
        id="addNewProduct-form"
        style={{ color: "var(--navBarBG)" }}
        method="POST"
      >
        <div className="mb-3" style={{ marginTop: "5rem" }}>
          <fieldset
            className="mb-4"
            style={{
              width: "100%",
              height: "100px",
              border: "1.5px dashed gray",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <legend
              style={{
                position: "absolute",
                top: "-1.3rem",
                backgroundColor: "white",
                color: "black",
                width: "auto",
                padding: "0 1rem",
              }}
            >
              Image 1
            </legend>
            <input
              type="file"
              name="image1"
              id="newProduct-Image1"
              accept="image/*"
              onChange={(event) => {
                const selectedFile = event.target.files?.item(0);
                const fileInput = event.target;
                if (!selectedFile?.type.startsWith("image/")) {
                  setErrors((prevErrors) => {
                    return { ...prevErrors, image1: "Please select an image" };
                  });
                  event.target.files = null;
                  setImage1(null);
                  fileInput.value = "";
                  return;
                } else {
                  setImage1(selectedFile);
                  setErrors((prevErrors) => {
                    return { ...prevErrors, image1: "" };
                  });
                }
              }}
              style={{
                backgroundColor: "gray",
                color: "white",
              }}
              required
            />
            <span style={{ color: "red" }}>{errors.image1}</span>
          </fieldset>
          <fieldset
            className="mb-4"
            style={{
              width: "100%",
              height: "100px",
              border: "1.5px dashed gray",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <legend
              style={{
                position: "absolute",
                top: "-1.3rem",
                backgroundColor: "white",
                color: "black",
                width: "auto",
                padding: "0 1rem",
              }}
            >
              Image 2
            </legend>
            <input
              type="file"
              name="image2"
              id="newProduct-Image2"
              accept="image/*"
              onChange={(event) => {
                const selectedFile = event.target.files?.item(0);
                const fileInput = event.target;
                if (!selectedFile?.type.startsWith("image/")) {
                  setErrors((prevErrors) => {
                    return { ...prevErrors, image2: "Please select an image" };
                  });
                  event.target.files = null;
                  setImage2(null);
                  fileInput.value = "";
                  return;
                } else {
                  setImage2(selectedFile);
                  setErrors((prevErrors) => {
                    return { ...prevErrors, image2: "" };
                  });
                }
              }}
              style={{
                backgroundColor: "gray",
                color: "white",
              }}
              // required
            />
            <span style={{ color: "red" }}>{errors.image2}</span>
          </fieldset>
          <fieldset
            className="mb-4"
            style={{
              width: "100%",
              height: "100px",
              border: "1.5px dashed gray",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <legend
              style={{
                position: "absolute",
                top: "-1.3rem",
                backgroundColor: "white",
                color: "black",
                width: "auto",
                padding: "0 1rem",
              }}
            >
              Image 3
            </legend>
            <input
              type="file"
              name="image3"
              id="newProduct-Image3"
              accept="image/*"
              onChange={(event) => {
                const selectedFile = event.target.files?.item(0);
                const fileInput = event.target;
                if (!selectedFile?.type.startsWith("image/")) {
                  setErrors((prevErrors) => {
                    return { ...prevErrors, image3: "Please select an image" };
                  });
                  event.target.files = null;
                  setImage3(null);
                  fileInput.value = "";
                  return;
                } else {
                  setImage3(selectedFile);
                  setErrors((prevErrors) => {
                    return { ...prevErrors, image3: "" };
                  });
                }
              }}
              style={{
                backgroundColor: "gray",
                color: "white",
              }}
              // required
            />
            <span style={{ color: "red" }}>{errors.image3}</span>
          </fieldset>
        </div>
        <div className="mb-3">
          <fieldset
            className="mb-4"
            style={{
              width: "100%",
              height: "100px",
              border: "1.5px dashed gray",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <legend
              style={{
                position: "absolute",
                top: "-1.3rem",
                backgroundColor: "white",
                color: "black",
                width: "auto",
                padding: "0 1rem",
              }}
            >
              Size Chart
            </legend>
            <input
              type="file"
              name="sizeChart"
              id="newProduct-sizeChart"
              accept="image/*"
              onChange={(event) => {
                const selectedFile = event.target.files?.item(0);
                const fileInput = event.target;
                if (!selectedFile?.type.startsWith("image/")) {
                  setErrors((prevErrors) => {
                    return {
                      ...prevErrors,
                      sizeChart: "Please select an image",
                    };
                  });
                  event.target.files = null;
                  setSizeChart(null);
                  fileInput.value = "";
                  return;
                } else {
                  setSizeChart(selectedFile);
                  setErrors((prevErrors) => {
                    return { ...prevErrors, sizeChart: "" };
                  });
                }
              }}
              style={{
                backgroundColor: "gray",
                color: "white",
              }}
              required
            />
            <span style={{ color: "red" }}>{errors.sizeChart}</span>
          </fieldset>
        </div>
        <div
          className="mb-3"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <label htmlFor="newProduct-InputCategory" className="form-label">
            Category
          </label>
          <select
            name="category"
            id="newProduct-InputCategory"
            value={category}
            onChange={(event) => setCategory(event.target.value)}
            style={{ fontSize: "1.2rem", padding: "5px 10px" }}
            required
          >
            <option value="" disabled hidden>
              Select a category
            </option>
            {/* <option value="men">Men</option>
            <option value="women">Women</option>
            <option value="unisex">Unisex</option>
            <option value="accessories">Accessories</option> */}
            <option value="t-shirts">T-Shirts</option>
            <option value="shirts">Shirts</option>
            <option value="shorts">Shorts</option>
            <option value="accessories">Accessories</option>
          </select>
          <span style={{ color: "red" }}>{errors.category}</span>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-InputTitle" className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="newProduct-InputTitle"
            style={{ borderRadius: "0", border: "1.5px solid gray" }}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            required
          />
          <span style={{ color: "red" }}>{errors.title}</span>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-description" className="form-label">
            Description
          </label>
          <ReactQuill
            id="newProduct-description"
            theme="snow"
            modules={modules}
            formats={formats}
            style={{ color: "black", width: "100%", height: "auto" }}
            value={description}
            onChange={(data) => {
              setDescription(data);
            }}
          />
          <span style={{ color: "red" }}>{errors.description}</span>
        </div>
        {/* sizes start */}

        {/* sizes end */}
        <div
          className="mb-3"
          // style={{ display: "flex", flexDirection: "column" }}
        >
          <label htmlFor="newProduct-InputPrice" className="form-label">
            Price
          </label>
          <div
            // className="mb-4"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                position: "absolute",
                width: "40px",
                textAlign: "center",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PKR
            </span>
            <input
              type="number"
              className="form-control"
              id="newProduct-InputPrice"
              value={(Number.parseInt(price) + "").replace(".", "")}
              onChange={(e) => {
                e.preventDefault();
                const newValue = e.target.value;
                if (newValue === "" || /^-?\d+$/.test(newValue)) {
                  setPrice(newValue);
                }
              }}
              style={{
                paddingLeft: "40px",
                borderRadius: "0",
                border: "1.5px solid gray",
              }}
              required
            />
          </div>
          <span style={{ color: "red" }}>{errors.price}</span>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-InputDiscount" className="form-label">
            Discount
          </label>
          <div
            className="mb-4"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                position: "absolute",
                width: "40px",
                textAlign: "center",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              %
            </span>
            <input
              type="number"
              className="form-control"
              id="newProduct-InputDiscount"
              value={(Number.parseInt(discount) + "").replace(".", "")}
              onChange={(e) => {
                e.preventDefault();
                const newValue = e.target.value;
                if (newValue === "" || /^-?\d+$/.test(newValue)) {
                  setDiscount(newValue);
                }
              }}
              style={{
                paddingLeft: "40px",
                borderRadius: "0",
                border: "1.5px solid gray",
              }}
              // required
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-sizes" className="form-label">
            Sizes
          </label>
          <fieldset
            id="newProduct-sizes"
            style={{
              padding: "1rem",
              border: "1.5px dashed gray",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {sizes.map((size, index) => (
              <>
                <div
                  key={index}
                  // style={{
                  //   marginBottom: "1rem",
                  // }}
                >
                  <div key={index} style={{ position: "relative" }}>
                    <input
                      type="text"
                      value={size}
                      onChange={(e) =>
                        handleSizeInputChange(index, e.target.value)
                      }
                      placeholder={`Size ${index + 1}`}
                      style={{
                        fontSize: "1.3rem",
                        padding: "0.2rem 1rem",
                      }}
                      required
                    />
                    <AiFillDelete
                      className="newProduct-SizeDelBtn"
                      style={{
                        fontSize: "1rem",
                        color: "white",
                        position: "absolute",
                        right: "0",
                        width: "30px",
                        backgroundColor: "red",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteSize(index)}
                    ></AiFillDelete>
                  </div>
                </div>
                <span style={{ color: "red", marginBottom: "1rem" }}>
                  {sizesErrors.at(index)}
                </span>
              </>
            ))}

            <button
              className="btn btn-secondary"
              style={{ borderRadius: "0" }}
              onClick={addSizeInput}
            >
              Add Size
            </button>
          </fieldset>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-styles" className="form-label">
            Styles
          </label>
          <fieldset
            style={{
              padding: "1rem",
              border: "1.5px dashed gray",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            id="newProduct-styles"
          >
            {styles.map((style, index) => (
              <>
                <div
                  key={index}
                  // style={{
                  //   marginBottom: "1rem",
                  // }}
                >
                  <div key={index} style={{ position: "relative" }}>
                    <input
                      type="text"
                      value={style}
                      onChange={(e) =>
                        handleStyleInputChange(index, e.target.value)
                      }
                      placeholder={`Style ${index + 1}`}
                      style={{
                        fontSize: "1.3rem",
                        padding: "0.2rem 1rem",
                      }}
                      required
                    />
                    <AiFillDelete
                      className="newProduct-SizeDelBtn"
                      style={{
                        fontSize: "1rem",
                        color: "white",
                        position: "absolute",
                        right: "0",
                        width: "30px",
                        backgroundColor: "red",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteStyle(index)}
                    ></AiFillDelete>
                  </div>
                </div>
                <span style={{ color: "red", marginBottom: "1rem" }}>
                  {stylesErrors.at(index)}
                </span>
              </>
            ))}
            <button
              className="btn btn-secondary"
              style={{ borderRadius: "0" }}
              onClick={addStyleInput}
            >
              Add Style
            </button>
          </fieldset>
        </div>
        <div
          className="mb-3"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            name="isOutOfStock"
            id="newProduct-isOutOfStock"
            style={{ marginRight: "5px" }}
            onChange={(event) => {
              setIsOutOfStock((prev) => !prev);
            }}
          />
          <label
            htmlFor="newProduct-isOutOfStock"
            style={{ userSelect: "none" }}
          >
            Out of Stock
          </label>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={async (event) => {
            event.preventDefault();
            setErrors({
              image1: "",
              image2: "",
              image3: "",
              sizeChart: "",
              title: "",
              description: "",
              price: "",
              category: "",
            });
            if (!image1) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  image1: "please select an image",
                };
              });
            }
            if (image1?.size && image1?.size / 1024 > 400) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  image1: "please select an image less than 400kb",
                };
              });
            }
            if (image2?.size && image2?.size / 1024 > 400) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  image2: "please select an image less than 400kb",
                };
              });
            }
            if (image3?.size && image3.size / 1024 > 400) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  image3: "please select an image less than 400kb",
                };
              });
            }
            if (sizeChart?.size && sizeChart.size / 1024 > 400) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  sizeChart: "please select an image less than 400kb",
                };
              });
            }

            if (!title) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  title: "title is mandatory",
                };
              });
            }
            if (category.length < 1) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  category: "category is mandatory",
                };
              });
            }
            // if (!description) {
            //   setErrors((prevErrors) => {
            //     return {
            //       ...prevErrors,
            //       description: "description is mandatory",
            //     };
            //   });
            // }
            // if (description.length <= 32) {
            //   setErrors((prevErrors) => {
            //     return {
            //       ...prevErrors,
            //       description:
            //         "description must contain 33 characters at least",
            //     };
            //   });
            // }
            if (!price) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  price: "price is mandatory",
                };
              });
            }
            if (Number.parseInt(price) < 1) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  price: "price is mandatory",
                };
              });
            }
            let sizesValid = true;
            let stylesValid = true;
            if (sizes) {
              sizes.map((value, index) => {
                if (value.length < 1) {
                  let temp = sizesErrors;
                  temp[index] = "empty size is not allowed";
                  setSizesErrors(temp);
                  sizesValid = false;
                } else {
                  let temp = sizesErrors;
                  temp[index] = "";
                  setSizesErrors(temp);
                }
              });
            }
            if (styles) {
              styles.map((value, index) => {
                if (value.length < 1) {
                  let temp = stylesErrors;
                  temp[index] = "empty style is not allowed";
                  setStylesErrors(temp);
                  stylesValid = false;
                } else {
                  let temp = stylesErrors;
                  temp[index] = "";
                  setStylesErrors(temp);
                }
              });
            }

            if (
              !(
                errors.category.length < 1 &&
                // errors.description.length < 1 &&
                errors.image1.length < 1 &&
                errors.image2.length < 1 &&
                errors.image3.length < 1 &&
                errors.price.length < 1 &&
                errors.title.length < 1 &&
                errors.sizeChart.length < 1
              )
            ) {
              return;
            }

            if (
              errors.category.length < 1 &&
              // errors.description.length < 1 &&
              errors.image1.length < 1 &&
              errors.image2.length < 1 &&
              errors.image3.length < 1 &&
              errors.price.length < 1 &&
              errors.title.length < 1 &&
              errors.sizeChart.length < 1
            ) {
              if (
                title.length > 0 &&
                // description.length > 32 &&
                Number.parseInt(price) > 0 &&
                image1 &&
                sizesValid &&
                stylesValid &&
                category.length > 0
              ) {
                setLoading(true);
                setLoadingMsg("Uploading Images");

                let imageCount = 1;
                if (image2) {
                  imageCount++;
                }
                if (image3) {
                  imageCount++;
                }
                /**
                 *  upload images one-by-one to http://localhost:3000/product/image and set loading msg to uploading images (1 of 3), (2 of 3), (3 of 3)
                 */
                let uploadedImages = [];
                let uploadedSizeChart = "";
                let tempFormData = new FormData();
                tempFormData.append("file", image1);
                setLoadingMsg(`Uploading Images (1 of ${imageCount})`);
                let result = await axios.post(
                  `${config.baseUrl}product/upload/image/one`,
                  tempFormData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
                uploadedImages.push(
                  `${config.imageBaseUrl}${result.data.filename}`
                );
                if (image2) {
                  tempFormData = new FormData();
                  tempFormData.append("file", image2);
                  setLoadingMsg(`Uploading Images (2 of ${imageCount})`);
                  result = await axios.post(
                    `${config.baseUrl}product/upload/image/one`,
                    tempFormData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  );
                  uploadedImages.push(
                    `${config.imageBaseUrl}${result.data.filename}`
                  );
                }
                if (image3) {
                  tempFormData = new FormData();
                  tempFormData.append("file", image3);
                  setLoadingMsg(
                    `Uploading Images (${
                      image1 && image2 && image3 ? "3" : "2"
                    } of ${imageCount})`
                  );
                  result = await axios.post(
                    `${config.baseUrl}product/upload/image/one`,
                    tempFormData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  );
                  uploadedImages.push(
                    `${config.imageBaseUrl}${result.data.filename}`
                  );
                }
                /**
                 *    update it
                 */
                if (sizeChart) {
                  tempFormData = new FormData();
                  tempFormData.append("file", sizeChart);
                  setLoadingMsg(`Uploading Size Chart`);
                  result = await axios.post(
                    `${config.baseUrl}product/upload/image/one`,
                    tempFormData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  );
                  uploadedSizeChart = `${config.imageBaseUrl}${result.data.filename}`;
                }
                /**
                 * upload remaining data
                 */
                setLoadingMsg("Uploading Other Data");
                let tempImages: { src: string | null; alt: string }[] = [];
                for (let i = 0; i < 3; i++) {
                  tempImages[i] = {
                    src: uploadedImages[i] ? uploadedImages[i] : null,
                    alt: `image-${i + 1}`,
                  };
                }
                const data = {
                  category: category,
                  images: tempImages,
                  sizeChart: uploadedSizeChart ? uploadedSizeChart : null,
                  title: title,
                  description: description,
                  sizes: sizes,
                  styles: styles,
                  price: price,
                  isOutOfStock: isOutOfStock,
                  discountPercentage: discount ? discount : 0,
                };
                try {
                  result = await axios.post(`${config.baseUrl}product`, data, {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  });
                } catch (error) {
                  console.log(error);
                }
                setLoadingMsg("Product added successfully!");
                // await sleep(1000);
                setDataUploaded(true);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                window.location.reload();
                setLoading(false);
                setLoadingMsg("");
                // alert();
              }
            }
          }}
          style={{ borderRadius: "0", backgroundColor: "var(--navBarBG)" }}
        >
          Add Product
        </button>
      </form>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "0",
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0,0,0,0.8)",
            backdropFilter: "blur(4px)",
            zIndex: "99999",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          {dataUploaded ? (
            <>
              <TiTick
                style={{ fontSize: "2rem", width: "2rem", height: "2rem" }}
              ></TiTick>
            </>
          ) : (
            <>
              <div
                className="spinner-border"
                style={{ width: "2rem", height: "2rem", marginBottom: "1rem" }}
                role="status"
              ></div>
            </>
          )}

          <span>{loadingMsg}</span>
        </div>
      )}
    </>
  );
}
