import axios from "axios";
import { format } from "path";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import config from "../config";
import { ProductsData } from "../types/TProduct";
import { LegacyRef, useEffect, useRef, useState } from "react";
import { TiTick } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { RiImageEditLine } from "react-icons/ri";
// import { Button, Modal } from "react-bootstrap";

export default function Products() {
  /**
   * handle authentication
   */
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token"]);
  const checkAuthentication = async () => {
    if (cookies) {
      const result = await axios.post(`${config.baseUrl}auth/status`, {
        access_token: cookies.access_token,
      });
      if (!result.data.authenticated) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  /**
   *  end authentication
   */
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PKR",
    maximumFractionDigits: 0,
  });

  const [products, setProducts] = useState<ProductsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [category, setCategory] = useState("t-shirts");
  const fetchProducts = async (categoryName: string) => {
    try {
      const response = await axios.get(
        `${config.baseUrl}product/category/${categoryName}`
      );
      setProducts(response.data);
    } catch (error: any) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request error:", error.request);
      } else {
        console.error("General error:", error.message);
      }
    }
  };
  useEffect(() => {
    fetchProducts(category);
  }, [category]);
  const [isDeleted, setIsDeleted] = useState(false);
  const handleDelete = async (id: number) => {
    setLoading(true);
    setError(null);
    try {
      let toReload = false;
      if (products && products?.length === 1) {
        toReload = true;
      }
      const response = await axios.delete(`${config.baseUrl}product/${id}`);
      await fetchProducts(category);
      setIsDeleted(true);
      if (toReload) {
        window.location.reload();
      }
    } catch (error: any) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        setError("failed to delete product.");
      } else if (error.request) {
        console.error("Request error:", error.request);
        setError("No response from server.");
      } else {
        console.error("General error:", error.message);
        setError("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div
        style={{
          marginTop: "5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <label
          htmlFor="newProduct-InputCategory"
          className="form-label"
          style={{
            color: "black",
            marginRight: "1rem",
            marginBottom: "0",
            fontSize: "1.2rem",
            fontWeight: "450",
          }}
        >
          Select Category
        </label>
        <select
          name="category"
          id="newProduct-InputCategory"
          value={category}
          onChange={(event) => setCategory(event.target.value)}
          style={{ fontSize: "1.2rem", padding: "5px 10px" }}
          required
        >
          <option value="" disabled hidden>
            Select a category
          </option>
          <option value="t-shirts">T-Shirts</option>
          <option value="shirts">Shirts</option>
          <option value="shorts">Shorts</option>
          <option value="accessories">Accessories</option>
        </select>
      </div>
      {products && products?.length > 0 ? (
        <div
          style={{
            marginTop: "1rem",
            color: "var(--navBarBG)",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            justifyItems: "center",
            gap: "16px",
            padding: "16px",
          }}
          className="container"
        >
          {products &&
            products.map((product, index) => (
              <div key={product.id}>
                {product.images ? (
                  <img
                    src={product.images.at(0)?.src}
                    alt={product.images.at(0)?.alt}
                    style={{
                      width: "300px",
                      height: "300px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "300px",
                      height: "300px",
                      backgroundColor: "gray",
                      fontSize: "2rem",
                      color: "rgba(255,255,255,0.7)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Image
                  </div>
                )}
                <div style={{ width: "300px" }}>
                  <div
                    style={{
                      height: "40px",
                      width: "100%",
                      backgroundColor: "var(--navBarBG)",
                      color: "white",
                      fontWeight: "600",
                      padding: "0 12px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "1.3rem",
                    }}
                  >
                    {product.title}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "170px",
                      backgroundColor: "rgba(0,0,0,0.3)",
                      wordWrap: "break-word",
                    }}
                  >
                    {product.description && (
                      <>
                        {product.description.length <= 300 ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.description,
                            }}
                          ></div>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: 4, // Limit to 4 lines
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: product.description.substring(0, 300),
                              }}
                            ></div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      height: "40px",
                      width: "100%",
                      backgroundColor: "var(--navBarBG)",
                      color: "white",
                      fontWeight: "600",
                      padding: "0 12px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "1.3rem",
                    }}
                  >
                    <span>
                      {formatter.format(Number.parseInt(product.price))}
                    </span>
                    {Number.parseInt(product.discountPercentage) > 0 && (
                      <span>
                        -{Number.parseInt(product.discountPercentage)}%
                      </span>
                    )}
                    <span
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        to={`/editProduct/${product.id}`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <AiFillEdit
                          style={{ marginRight: "1rem" }}
                          className="productEditButton"
                        />
                      </Link>
                      <AiFillDelete
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="productDeleteButton"
                        onClick={() => {
                          setIsDeleted(false);
                          setToDelete(product.id);
                        }}
                      />
                    </span>
                  </div>
                  <div
                    style={{
                      borderTop: "1px solid white",
                      height: "50px",
                      width: "100%",
                      backgroundColor: "var(--navBarBG)",
                      color: "white",
                      fontWeight: "600",
                      padding: "0 12px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      fontSize: "1.3rem",
                    }}
                  >
                    <Link
                      to={`/editSizeCard/${product.id}`}
                      className="btn btn-secondary"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Update Size Card{" "}
                      <RiImageEditLine
                        style={{ marginLeft: "5px" }}
                      ></RiImageEditLine>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    {isDeleted ? "Successfull" : "Confirm Delete"}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  className="modal-body"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1.2rem",
                    height: "150px",
                  }}
                >
                  {isDeleted ? (
                    <>
                      <span>
                        <TiTick
                          style={{
                            fontSize: "5rem",
                            backgroundColor: "green",
                            borderRadius: "5rem",
                            color: "white",
                            marginBottom: "5px",
                          }}
                        ></TiTick>
                      </span>
                      <span>Product is successfully deleted!</span>
                    </>
                  ) : (
                    <>Are you sure you want to delete this product?</>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    disabled={loading}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    style={{ borderRadius: "0" }}
                  >
                    Close
                  </button>
                  {!isDeleted && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ width: "100px", borderRadius: "0" }}
                      disabled={loading}
                      onClick={() => {
                        if (toDelete) {
                          handleDelete(toDelete);
                        }
                      }}
                    >
                      {loading ? (
                        <div
                          className="spinner-border"
                          style={{ width: "1.3rem", height: "1.3rem" }}
                          role="status"
                        ></div>
                      ) : (
                        "Delete"
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            marginTop: "2rem",
            color: "gray",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2rem",
          }}
        >
          No Products Found
        </div>
      )}
    </>
  );
}
