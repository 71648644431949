import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import config from "../config";
import { TiTick } from "react-icons/ti";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactQuill from "react-quill";

export default function EditProduct() {
  /**
   * handle authentication
   */
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token"]);
  const checkAuthentication = async () => {
    if (cookies) {
      const result = await axios.post(`${config.baseUrl}auth/status`, {
        access_token: cookies.access_token,
      });
      if (!result.data.authenticated) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  /**
   *  end authentication
   */
  /**
   *  for text editor
   */
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];
  /**
   *  end
   */
  /**
   * fetch previous data
   */
  const { id } = useParams();
  const navigator = useNavigate();
  let [previousData, setPreviousData] = useState<any>();
  const fetchPreviousData = async () => {
    try {
      const data = await axios.get(`${config.baseUrl}product/${id}`);
      setPreviousData(data.data);
    } catch (error) {
      console.log("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    fetchPreviousData();
  }, [id]);
  // console.log(previousData);
  //   fetchPreviousData();
  //   console.log(previousData);
  /**
   * fetching completed
   */
  const [loading, setLoading] = useState(false);
  const [dataUploaded, setDataUploaded] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  /** */
  const [category, setCategory] = useState("");
  const [sizes, setSizes] = useState<string[]>([]);
  const [sizesErrors, setSizesErrors] = useState<string[]>([]);
  const [styles, setStyles] = useState<string[]>([]);
  const [stylesErrors, setStylesErrors] = useState<string[]>([]);
  const [discount, setDiscount] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [isOutOfStock, setIsOutOfStock] = useState<boolean>(false);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    price: "",
    category: "",
  });
  const addSizeInput = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (sizes) {
      setSizes([...sizes, ""]); // Add a new empty string to the sizes array
      setSizesErrors([...sizesErrors, ""]);
    } else {
      setSizes([""]);
      setSizesErrors([""]);
    }
  };
  const addStyleInput = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (styles) {
      setStyles([...styles, ""]); // Add a new empty string to the sizes array
      setStylesErrors([...stylesErrors, ""]);
    } else {
      setStyles([""]); // Add a new empty string to the sizes array
      setStylesErrors([""]);
    }
  };

  // Function to handle changes in the input fields
  const handleSizeInputChange = (index: any, value: any) => {
    const newSizes = sizes.slice(); // Create a copy of the sizes array
    newSizes[index] = value; // Update the specific index
    setSizes(newSizes); // Set the new sizes array
  };
  const handleStyleInputChange = (index: any, value: any) => {
    const newStyles = styles.slice(); // Create a copy of the sizes array
    newStyles[index] = value; // Update the specific index
    setStyles(newStyles); // Set the new sizes array
  };

  const deleteSize = (index: number) => {
    setSizes((prevSizes) => prevSizes.filter((size, _i) => _i != index));
    setSizesErrors((prevErrors) => prevErrors.filter((e, _i) => _i != index));
  };
  const deleteStyle = (index: number) => {
    setStyles((prevStyles) => prevStyles.filter((style, _i) => _i != index));
    setStylesErrors((prevErrors) => prevErrors.filter((e, _i) => _i != index));
  };

  useEffect(() => {
    if (previousData) {
      // console.log(previousData);
      setTitle(previousData.title);
      setCategory(previousData.category);
      setDescription(previousData.description);
      setPrice(previousData.price);
      setSizes(previousData.sizes);
      setStyles(previousData.styles);
      setDiscount(previousData.discountPercentage);
      setIsOutOfStock(previousData.isOutOfStock);
    }
  }, [previousData]);
  return (
    <>
      <form
        className="container mb-5"
        id="addNewProduct-form"
        style={{ marginTop: "5rem", color: "var(--navBarBG)" }}
      >
        <div
          className="mb-3"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <label htmlFor="newProduct-InputCategory" className="form-label">
            Category
          </label>
          <select
            name="category"
            id="newProduct-InputCategory"
            value={category}
            onChange={(event) => setCategory(event.target.value)}
            style={{ fontSize: "1.2rem", padding: "5px 10px" }}
            required
          >
            <option value="" disabled hidden>
              Select a category
            </option>
            <option value="t-shirts">T-Shirts</option>
            <option value="shirts">Shirts</option>
            <option value="shorts">Shorts</option>
            <option value="accessories">Accessories</option>
            {/* <option value="men">Men</option>
            <option value="women">Women</option>
            <option value="unisex">Unisex</option>
            <option value="accessories">Accessories</option> */}
          </select>
          <span style={{ color: "red" }}>{errors.category}</span>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-InputTitle" className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="newProduct-InputTitle"
            style={{ borderRadius: "0", border: "1.5px solid gray" }}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            required
          />
          <span style={{ color: "red" }}>{errors.title}</span>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-description" className="form-label">
            Description
          </label>
          <ReactQuill
            id="newProduct-description"
            theme="snow"
            modules={modules}
            formats={formats}
            style={{ color: "black", width: "100%", height: "auto" }}
            value={description}
            onChange={(data) => {
              setDescription(data);
            }}
          />
          {/* <textarea
            className="form-control"
            id="newProduct-description"
            maxLength={300}
            minLength={32}
            required
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            style={{
              height: "140px",
              borderRadius: "0",
              border: "1.5px solid gray",
              resize: "none",
            }}
          ></textarea> */}
          <span style={{ color: "red" }}>{errors.description}</span>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-InputPrice" className="form-label">
            Price
          </label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                position: "absolute",
                width: "40px",
                textAlign: "center",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PKR
            </span>
            <input
              type="number"
              className="form-control"
              id="newProduct-InputPrice"
              value={(Number.parseInt(price) + "").replace(".", "")}
              onChange={(e) => {
                e.preventDefault();
                const newValue = e.target.value;
                if (newValue === "" || /^-?\d+$/.test(newValue)) {
                  setPrice(newValue);
                }
              }}
              style={{
                paddingLeft: "40px",
                borderRadius: "0",
                border: "1.5px solid gray",
              }}
              required
            />
          </div>
          <span style={{ color: "red" }}>{errors.price}</span>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-InputDiscount" className="form-label">
            Discount
          </label>
          <div
            className="mb-4"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                position: "absolute",
                width: "40px",
                textAlign: "center",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              %
            </span>
            <input
              type="number"
              className="form-control"
              id="newProduct-InputDiscount"
              value={(Number.parseInt(discount) + "").replace(".", "")}
              onChange={(e) => {
                e.preventDefault();
                const newValue = e.target.value;
                if (newValue === "" || /^-?\d+$/.test(newValue)) {
                  setDiscount(newValue);
                }
              }}
              style={{
                paddingLeft: "40px",
                borderRadius: "0",
                border: "1.5px solid gray",
              }}
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-sizes" className="form-label">
            Sizes
          </label>
          <fieldset
            id="newProduct-sizes"
            style={{
              padding: "1rem",
              border: "1.5px dashed gray",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {sizes &&
              sizes.map((size, index) => (
                <>
                  <div key={index}>
                    <div key={index} style={{ position: "relative" }}>
                      <input
                        type="text"
                        value={size}
                        onChange={(e) =>
                          handleSizeInputChange(index, e.target.value)
                        }
                        placeholder={`Size ${index + 1}`}
                        style={{
                          fontSize: "1.3rem",
                          padding: "0.2rem 1rem",
                        }}
                        required
                      />
                      <AiFillDelete
                        className="newProduct-SizeDelBtn"
                        style={{
                          fontSize: "1rem",
                          color: "white",
                          position: "absolute",
                          right: "0",
                          width: "30px",
                          backgroundColor: "red",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteSize(index)}
                      ></AiFillDelete>
                    </div>
                  </div>
                  <span style={{ color: "red", marginBottom: "1rem" }}>
                    {sizesErrors.at(index)}
                  </span>
                </>
              ))}
            <button
              className="btn btn-secondary"
              style={{ borderRadius: "0" }}
              onClick={addSizeInput}
            >
              Add Size
            </button>
          </fieldset>
        </div>
        <div className="mb-3">
          <label htmlFor="newProduct-styles" className="form-label">
            Styles
          </label>
          <fieldset
            style={{
              padding: "1rem",
              border: "1.5px dashed gray",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            id="newProduct-styles"
          >
            {styles &&
              styles.map((style, index) => (
                <>
                  <div key={index}>
                    <div key={index} style={{ position: "relative" }}>
                      <input
                        type="text"
                        value={style}
                        onChange={(e) =>
                          handleStyleInputChange(index, e.target.value)
                        }
                        placeholder={`Style ${index + 1}`}
                        style={{
                          fontSize: "1.3rem",
                          padding: "0.2rem 1rem",
                        }}
                        required
                      />
                      <AiFillDelete
                        className="newProduct-SizeDelBtn"
                        style={{
                          fontSize: "1rem",
                          color: "white",
                          position: "absolute",
                          right: "0",
                          width: "30px",
                          backgroundColor: "red",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteStyle(index)}
                      ></AiFillDelete>
                    </div>
                  </div>
                  <span style={{ color: "red", marginBottom: "1rem" }}>
                    {stylesErrors.at(index)}
                  </span>
                </>
              ))}
            <button
              className="btn btn-secondary"
              style={{ borderRadius: "0" }}
              onClick={addStyleInput}
            >
              Add Style
            </button>
          </fieldset>
        </div>
        <div
          className="mb-3"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            name="isOutOfStock"
            id="newProduct-isOutOfStock"
            style={{ marginRight: "5px" }}
            checked={isOutOfStock}
            onChange={(event) => {
              setIsOutOfStock((prev) => !prev);
            }}
          />
          <label
            htmlFor="newProduct-isOutOfStock"
            style={{ userSelect: "none" }}
          >
            Out of Stock
          </label>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={async (event) => {
            event.preventDefault();
            setErrors({
              title: "",
              description: "",
              price: "",
              category: "",
            });
            if (!title) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  title: "title is mandatory",
                };
              });
            }
            if (category.length < 1) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  category: "category is mandatory",
                };
              });
            }
            // if (!description) {
            //   setErrors((prevErrors) => {
            //     return {
            //       ...prevErrors,
            //       description: "description is mandatory",
            //     };
            //   });
            // }
            // if (description.length <= 32) {
            //   setErrors((prevErrors) => {
            //     return {
            //       ...prevErrors,
            //       description:
            //         "description must contain 33 characters at least",
            //     };
            //   });
            // }
            if (!price) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  price: "price is mandatory",
                };
              });
            }
            if (Number.parseInt(price) < 1) {
              setErrors((prevErrors) => {
                return {
                  ...prevErrors,
                  price: "price is mandatory",
                };
              });
            }
            let sizesValid = true;
            let stylesValid = true;
            if (sizes) {
              sizes.map((value, index) => {
                if (value.length < 1) {
                  let temp = sizesErrors;
                  temp[index] = "empty size is not allowed";
                  setSizesErrors(temp);
                  sizesValid = false;
                } else {
                  let temp = sizesErrors;
                  temp[index] = "";
                  setSizesErrors(temp);
                }
              });
            }
            if (styles) {
              styles.map((value, index) => {
                if (value.length < 1) {
                  let temp = stylesErrors;
                  temp[index] = "empty style is not allowed";
                  setStylesErrors(temp);
                  stylesValid = false;
                } else {
                  let temp = stylesErrors;
                  temp[index] = "";
                  setStylesErrors(temp);
                }
              });
            }
            if (
              title.length > 0 &&
              // description.length > 32 &&
              Number.parseInt(price) > 0 &&
              sizesValid &&
              stylesValid &&
              category.length > 0
            ) {
              setLoading(true);
              setLoadingMsg("Updating Product");
              const data = {
                category: category,
                title: title,
                description: description,
                sizes: sizes,
                styles: styles,
                price: price,
                isOutOfStock: isOutOfStock,
                discountPercentage: discount ? discount : 0,
              };
              try {
                let result = await axios.put(
                  `${config.baseUrl}product/${id}`,
                  data,
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );
              } catch (error) {
                console.log(error);
              }
              setLoadingMsg("Product updated successfully!");
              setDataUploaded(true);
              await new Promise((resolve) => setTimeout(resolve, 1000));
              navigator("/products");
              setLoading(false);
              setLoadingMsg("");
            }
          }}
          style={{ borderRadius: "0", backgroundColor: "var(--navBarBG)" }}
        >
          Update Product
        </button>
      </form>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "0",
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0,0,0,0.8)",
            backdropFilter: "blur(4px)",
            zIndex: "99999",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          {dataUploaded ? (
            <>
              <TiTick
                style={{ fontSize: "2rem", width: "2rem", height: "2rem" }}
              ></TiTick>
            </>
          ) : (
            <>
              <div
                className="spinner-border"
                style={{ width: "2rem", height: "2rem", marginBottom: "1rem" }}
                role="status"
              ></div>
            </>
          )}
          <span>{loadingMsg}</span>
        </div>
      )}
    </>
  );
}
