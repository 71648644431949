import axios from "axios";
import { useEffect, useState } from "react";
import config from "../config";
import { Product } from "../interface/IProduct";
import { convertToDate } from "../functions/coverToDate";
import { convertToPakistanTime } from "../functions/convertToPakistanTime";
import { TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Orders() {
  /**
   * handle authentication
   */
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token"]);
  const checkAuthentication = async () => {
    if (cookies) {
      const result = await axios.post(`${config.baseUrl}auth/status`, {
        access_token: cookies.access_token,
      });
      if (!result.data.authenticated) {
        setIsAuthenticated(false);
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    }
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  /**
   *  end authentication
   */
  const [orders, setOrders] = useState<
    | {
        id: number;
        fullName: string;
        email: string;
        phonenumbers: string[];
        address: string;
        city: string;
        country: string;
        order: {
          product: Product;
          productQuantity: number;
          style: string;
          size: string;
        }[];
        //  order: any;
        totalAmount: number;
        totalDiscount: number;
        newAmount: number;
        placedAt: string;
        status: string;
        statusUpdatedAt: string;
        courierId: string | null;
        orderToken: string;
      }[]
    | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [orderUpdated, setOrderUpdated] = useState(false);
  const [courierIdInput, setCourierIdInput] = useState("");
  const [courierIdInputError, setCourierIdInputError] = useState("");
  const [newStatus, setNewStatus] = useState("received");
  const fetchOrders = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${config.baseUrl}orders/`);
      setOrders(response.data);
    } catch (error: any) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        setError("Failed to fetch products.");
      } else if (error.request) {
        console.error("Request error:", error.request);
        setError("No response from server.");
      } else {
        console.error("General error:", error.message);
        setError("An error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };
  const selectOrder = (id: number) => {
    setSelectedOrder(id);
  };
  useEffect(() => {
    fetchOrders();
  }, []);
  // useEffect(() => {
  //   orders?.map((_o, _i) => {
  //     _o.order.map((item, index) => {
  //       setAmount((p) => p + Number.parseInt(item.product.price));
  //       setDiscount(
  //         (p) =>
  //           p +
  //           (Number.parseInt(item.product.price) *
  //             Number.parseInt(item.product.discountPercentage)) /
  //             100
  //       );
  //       setTotalAmount(
  //         (p) =>
  //           p +
  //           (Number.parseInt(item.product.price) -
  //             (Number.parseInt(item.product.price) *
  //               Number.parseInt(item.product.discountPercentage)) /
  //               100)
  //       );
  //     });
  //   });
  //   console.log("price: ", amount);
  //   console.log("discount: ", discount);
  //   console.log("t-price: ", totalAmount);
  // }, [orders]);
  // useEffect(() => {
  //   console.log(products);
  // }, [products]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PKR",
    maximumFractionDigits: 0,
  });
  return (
    <div style={{ marginTop: "5rem" }}>
      <div
        style={{
          position: "fixed",
          top: "0px",
          backgroundColor: "white",
          width: "100%",
          paddingTop: "80px",
          // paddingBottom: "1rem",
        }}
      >
        <button
          onClick={fetchOrders}
          disabled={loading}
          className="btn-dark"
          style={{
            marginLeft: "2rem",
            // marginBottom: "1rem",
            border: "none",
            color: "white",
            width: "100px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <div
              className="spinner-border"
              style={{ width: "1.4rem", height: "1.4rem" }}
              role="status"
            ></div>
          ) : (
            "Refresh"
          )}
        </button>

        {error && <div style={{ color: "red" }}>{error}</div>}
      </div>
      <table className="table" style={{ marginTop: "120px" }}>
        <thead>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "center",
                minWidth: "70px",
              }}
            >
              id
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                minWidth: "200px",
              }}
            >
              Full Name
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                minWidth: "180px",
                wordBreak: "break-all",
                overflow: "break-word",
                textWrap: "wrap",
                wordWrap: "break-word",
              }}
            >
              Email
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                minWidth: "150px",
              }}
            >
              Phonenumbers
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "200px" }}>
              Address
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "100px" }}>
              City
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Country
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "300px" }}>
              Order
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Amount
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Discount
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Total Amount
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Order Placed At
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "200px" }}>
              Courier Id
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Order Token
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Status
            </th>
            <th scope="col" style={{ textAlign: "center", minWidth: "150px" }}>
              Status Updated At
            </th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders.map((order) => {
              if (order.status != "rejected" && order.status != "completed") {
                orders.sort((a, b) => {
                  const dateA = new Date(a.placedAt).getTime();
                  const dateB = new Date(b.placedAt).getTime();
                  return dateB - dateA; // Sort in descending order (latest dates first)
                });
                return (
                  <tr
                    className="orderRow"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() => {
                      selectOrder(order.id);
                      setLoading(false);
                      setOrderUpdated(false);
                      setCourierIdInput("");
                      setNewStatus("received");
                    }}
                  >
                    <th
                      scope="row"
                      key={order.id}
                      style={{ textAlign: "center" }}
                    >
                      {order.id}
                    </th>
                    <td
                      style={{
                        textAlign: "center",
                        minWidth: "200px",
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {order.fullName}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        minWidth: "180px",
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {order.email}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {/* {order.phonenumbers.split('","').map((_p, _i) => {
                        let temp = _p.replace('["', "");
                        return <div>{temp.replace('"]', "")}</div>;
                      })} */}
                      {order.phonenumbers.map((_p) => (
                        <div>{_p}</div>
                      ))}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        minWidth: "200px",
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {order.address}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        minWidth: "100px",
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {order.city}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        minWidth: "150px",
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {order.country}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        minWidth: "300px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <div
                          style={{
                            minWidth: "300px",
                            fontWeight: "550",
                            display: "flex",
                            justifyContent: "center",
                            borderRight: "1px solid black",
                          }}
                        >
                          Products
                        </div>
                        <div
                          style={{
                            minWidth: "100px",
                            fontWeight: "550",
                            display: "flex",
                            justifyContent: "center",
                            borderRight: "1px solid black",
                            // backgroundColor: "red",
                          }}
                        >
                          Quantity
                        </div>
                        <div
                          style={{
                            minWidth: "100px",
                            fontWeight: "550",
                            display: "flex",
                            justifyContent: "center",
                            borderRight: "1px solid black",
                            // backgroundColor: "red",
                          }}
                        >
                          Size
                        </div>
                        <div
                          style={{
                            minWidth: "100px",
                            fontWeight: "550",
                            display: "flex",
                            justifyContent: "center",
                            // backgroundColor: "red",
                          }}
                        >
                          Style
                        </div>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <div
                          style={{
                            minWidth: "70%",
                            fontWeight: "550",
                            display: "flex",
                            justifyContent: "center",
                            borderRight: "1px solid black",
                          }}
                        >
                          Products
                        </div>
                        <div
                          style={{
                            minWidth: "30%",
                            fontWeight: "550",
                            display: "flex",
                            justifyContent: "center",
                            // backgroundColor: "red",
                          }}
                        >
                          Quantity
                        </div>
                      </div> */}
                      {order.order.map((_o, _i) => (
                        <div
                          className="d-flex"
                          style={
                            _i != order.order.length - 1
                              ? { borderBottom: "1px solid black" }
                              : {}
                          }
                        >
                          <div
                            style={{
                              minWidth: "300px",
                              display: "flex",
                              justifyContent: "center",
                              borderRight: "1px solid black",
                              //   backgroundColor: "red",
                              wordBreak: "break-all",
                              overflow: "break-word",
                              textWrap: "wrap",
                              textAlign: "left",
                              wordWrap: "break-word",
                            }}
                          >
                            {_o.product.title}
                          </div>
                          <div
                            style={{
                              minWidth: "100px",
                              display: "flex",
                              justifyContent: "center",
                              borderRight: "1px solid black",
                              //   backgroundColor: "green",
                            }}
                          >
                            {_o.productQuantity}
                          </div>
                          <div
                            style={{
                              minWidth: "100px",
                              display: "flex",
                              justifyContent: "center",
                              borderRight: "1px solid black",
                              //   backgroundColor: "green",
                            }}
                          >
                            {_o.size
                              ? order.order
                                  .at(_i)
                                  ?.product.sizes.at(Number.parseInt(_o.size))
                              : "-"}
                          </div>
                          <div
                            style={{
                              minWidth: "100px",
                              display: "flex",
                              justifyContent: "center",
                              //   backgroundColor: "green",
                            }}
                          >
                            {_o.style
                              ? order.order
                                  .at(_i)
                                  ?.product.styles.at(Number.parseInt(_o.style))
                              : "-"}
                          </div>
                        </div>
                      ))}
                      {/* {order.order.map((_o, _i) => (
                        <div
                          className="d-flex"
                          style={
                            _i != order.order.length - 1
                              ? { borderBottom: "1px solid black" }
                              : {}
                          }
                        >
                          <div
                            style={{
                              minWidth: "70%",
                              display: "flex",
                              justifyContent: "center",
                              borderRight: "1px solid black",
                              //   backgroundColor: "red",
                              wordBreak: "break-all",
                              overflow: "break-word",
                              textWrap: "wrap",
                              textAlign: "left",
                              wordWrap: "break-word",
                            }}
                          >
                            {_o.product.title}
                          </div>
                          <div
                            style={{
                              minWidth: "30%",
                              display: "flex",
                              justifyContent: "center",
                              //   backgroundColor: "green",
                            }}
                          >
                            {_o.productQuantity}
                          </div>
                        </div>
                      ))} */}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {formatter.format(order.totalAmount)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {formatter.format(order.totalDiscount)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {formatter.format(order.newAmount)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {`${convertToDate(order.placedAt)}, ${new Date(
                        order.placedAt
                      ).toTimeString()}`}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        minWidth: "200px",
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        // textAlign: "left",
                        wordWrap: "break-word",
                      }}
                    >
                      {order.courierId ? (
                        order.courierId
                      ) : (
                        <span style={{ fontStyle: "italic", color: "gray" }}>
                          not set
                        </span>
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>{order.orderToken}</td>
                    <td style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor:
                            order.status === "received" ? "orange" : "blue",
                          padding: "5px 15px",
                          borderRadius: "100px",
                          color: "white",
                        }}
                      >
                        {order.status}
                      </span>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {`${convertToDate(order.statusUpdatedAt)}, ${new Date(
                        order.statusUpdatedAt
                      ).toTimeString()}`}
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </table>
      {/* <div style={{ color: "black" }}>{products && products[0].title}</div>; */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="exampleModalLabel"
                style={{ color: "black" }}
              >
                {orderUpdated ? "Successfull" : "Update Order"}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.2rem",
                height: "150px",
                color: "black",
              }}
            >
              {orderUpdated ? (
                <>
                  <span>
                    <TiTick
                      style={{
                        fontSize: "5rem",
                        backgroundColor: "green",
                        borderRadius: "5rem",
                        color: "white",
                        marginBottom: "5px",
                      }}
                    ></TiTick>
                  </span>
                  <span>Product is updated successfully!</span>
                </>
              ) : (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <div style={{ width: "25%" }}>New Status: </div>
                    <select
                      style={{ width: "75%", padding: "5px 10px" }}
                      onChange={(event) => {
                        setNewStatus(event.target.value);
                      }}
                    >
                      <option value="received" key={0}>
                        received
                      </option>
                      <option value="dispatched" key={1}>
                        dispatched
                      </option>
                      <option value="completed" key={2}>
                        completed
                      </option>
                      <option value="rejected" key={3}>
                        rejected
                      </option>
                    </select>
                  </div>

                  {newStatus === "dispatched" && (
                    <>
                      <input
                        type="text"
                        value={courierIdInput}
                        onChange={(event) => {
                          if (courierIdInput.length > 0) {
                            setCourierIdInputError("");
                          }
                          setCourierIdInput(event.target.value);
                        }}
                        placeholder="courier id"
                        style={{ padding: "5px 10px", width: "100%" }}
                      ></input>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          color: "red",
                        }}
                      >
                        {courierIdInputError}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                disabled={loading}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                style={{ borderRadius: "0" }}
              >
                Close
              </button>
              {!orderUpdated && (
                <button
                  type="button"
                  className="btn btn-danger"
                  style={{ width: "100px", borderRadius: "0" }}
                  disabled={loading}
                  onClick={async () => {
                    if (newStatus === "dispatched") {
                      if (courierIdInput.length < 1) {
                        setCourierIdInputError("please enter courier id");
                      } else {
                        setLoading(true);
                        setCourierIdInputError("");
                        await axios.put(`${config.baseUrl}orders/status`, {
                          id: selectedOrder,
                          status: newStatus,
                        });
                        await axios.put(`${config.baseUrl}orders/courierId`, {
                          id: selectedOrder,
                          courierId: courierIdInput,
                        });
                        setLoading(false);
                        setOrderUpdated(true);
                        setCourierIdInput("");
                        setNewStatus("received");
                      }
                    } else {
                      setLoading(true);
                      const result = await axios.put(
                        `${config.baseUrl}orders/status`,
                        {
                          id: selectedOrder,
                          status: newStatus,
                        }
                      );
                    }
                    setLoading(false);
                    setOrderUpdated(true);
                    setCourierIdInput("");
                    setNewStatus("received");
                  }}
                >
                  {loading ? (
                    <div
                      className="spinner-border"
                      style={{ width: "1.3rem", height: "1.3rem" }}
                      role="status"
                    ></div>
                  ) : (
                    "Update"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
