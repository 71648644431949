import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config";
import { useCookies } from "react-cookie";

export default function NavBar() {
  const [cookies, setCookie] = useCookies(["access_token"]);
  const [messagesCount, setMessagesCount] = useState<number | undefined>();
  const [newOrdersCount, setNewOrdersCount] = useState<number | undefined>();
  const [oldOrdersCount, setOldOrdersCount] = useState<number | undefined>();
  const fetchMessageCount = async () => {
    const count = await axios.get(`${config.baseUrl}messages/count/all`);
    setMessagesCount(count.data);
  };
  const fetchNewOrdersCount = async () => {
    const count = await axios.get(`${config.baseUrl}orders/count/newOrders`);
    setNewOrdersCount(count.data.message);
  };
  const fetchOldOrdersCount = async () => {
    const count = await axios.get(`${config.baseUrl}orders/count/oldOrders`);
    setOldOrdersCount(count.data.message);
  };
  useEffect(() => {
    fetchMessageCount();
  }, [messagesCount]);
  useEffect(() => {
    fetchNewOrdersCount();
  }, [newOrdersCount]);
  useEffect(() => {
    fetchOldOrdersCount();
  }, [oldOrdersCount]);
  return (
    <nav
      className="navbar navbar-expand-lg bg-body-tertiary bg-dark"
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        zIndex: "999",
      }}
    >
      <div className="container-fluid navbar-dark">
        <Link className="navbar-brand" to={"/"} style={{ fontWeight: "550" }}>
          RottenAttire
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/">
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/products">
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/newProduct">
                Add New Product
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/messages">
                Messages<sup>{messagesCount}</sup>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/orders">
                New Orders<sup>{newOrdersCount}</sup>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                aria-current="page"
                to="/previousOrders"
              >
                Previous Orders<sup>{oldOrdersCount}</sup>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                aria-current="page"
                to="/login"
                onClick={() => {
                  setCookie("access_token", "");
                }}
              >
                SignOut
              </Link>
            </li>
          </ul>
          {/* <form className="d-flex" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
        </div>
      </div>
    </nav>
  );
}
